.wrapperItemBodyGate .wrapper, .pointer-events-none, img
  border-radius: 10px

.wrapperItemBodyGate
  font-weight: 600
  position: relative
  color: $black
  padding: 10px
  border-radius: 10px
  text-align: center
  &:first-child
    margin-right: 5px

  &:last-child
    margin-left: 5px
  .wrapper, a
    img
      width: 100%
      height: 100%

.wrapperItemBodyGate.disable:before
  content: ''
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  background-color: #797979
  border-radius: inherit
  opacity: 0.6
  cursor: url("../assets/svg/ban-solid.svg"), auto






