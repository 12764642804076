@import "../GlobalStyles"

.previewCameraImg
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.hiddenCamera // I need think how to fix it
    animation-name: zoom
    animation-duration: 0.6s
    -webkit-animation-name: zoom
    -webkit-animation-duration: 0.6s

.wrapperCamera
    width: 600px
    padding: 15px 0
    border-radius: 10px
    background-color: #1f618d
    display: flex
    flex-direction: column
    margin-bottom: 10px
    box-shadow: 0 0 17px 0 #25ad96
    position: relative
    z-index: 1
    &__header
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 10px
        color: #fff
        text-transform: uppercase
        h4
            font-weight: 500
            margin: 0
            &:last-child
                margin-right: 30px

    &__sectionCamera
        display: flex
        justify-content: space-evenly
        .wrapperImages
            background-color: #fff
            padding: 5px
            border-radius: 10px
            width: 300px
            height: 250px
            text-align: center
            .imageBlock
                position: relative
                border-radius: inherit
                overflow: hidden
                height: 100%
                width: 100%
                .ant-carousel,
                .slick-slider,
                .slick-list,
                .slick-track
                    height: 100% !important

                .slick-slide > div:first-child
                    height: 100% !important


                .settingImageLoading
                    display: flex
                    justify-content: center
                    align-items: center
                    height: 100%
                &__loading
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
                &__error
                    img
                        width: 100%


            .smallImgCamera
                width: 100%
                height: 100%
                object-fit: cover
                border-radius: inherit
                cursor: pointer
            .sectionInputs
                margin-top: 10px
                display: flex
                justify-content: center
                align-items: center
            button
                cursor: pointer
                width: 130px
                height: 30px
                background-color: #3C60B5
                border-radius: 10px
                color: #fff
                text-transform: uppercase
                font-weight: 600
                font-size: 0.8rem
                &:hover
                    opacity: 0.8
                    transition: all 0.4s
                &:disabled
                    background-color: rgba(0, 0, 0, 0.25)
                    cursor: not-allowed
        .informationCamera
            text-align: center
            width: 200px
            height: 250px
            background-color: #ffffff
            border-radius: 10px
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column
            span
                width: 100%
                margin-bottom: 10px
                text-transform: uppercase
                font-size: 1.1rem
                font-weight: 600
    .sectionButton
        position: absolute
        right: 0
        top: 0
        z-index: 2
        button
            @extend .iconButton
            font-size: 1.5rem
            svg
                color: #fff !important
                &:hover
                    fill: #000000
            &:disabled
                background-color: rgba(0, 0, 0, 0.25)
                cursor: not-allowed
        span
            font-size: 1.5rem


.wrapperCameraButtons
    button
        @extend .defaultButton
        font-size: 0.9rem
        &:first-child
            margin-right: 10px
            width: 200px


