@import "GlobalStyles"

.main_header
  background-color: $easy-blue
  text-align: left
  height: $main_header_height
  color: $white
  font-size: 15px
  display: flex
  align-items: center
  padding: 0 20px 0 5px

  &__wrapperLeftSidebar
    display: flex
    align-items: center
    width: 100%
    max-width: 80%

    .ant-menu-horizontal,
    .ant-menu-light,
    .headerNavigation
      background-color: transparent
      border: none
      display: flex
      flex: 1
      margin: 0 0 0 20px

      .ant-menu-overflow-item
        padding: 0
        margin-right: 20px

        &:last-child
          margin-right: 0

      .ant-menu-item, .ant-menu-submenu
        font-size: 0.9rem
        font-weight: 600

        &:after
          inset-inline: 0
      .ant-menu-item-selected,
      .ant-menu-submenu-selected
        color: white
        .ant-menu-title-content
          color: white

      .ant-menu-item-selected,
      .ant-menu-submenu-selected,
      .ant-menu-submenu-active
        a
          margin-right: 0

        &:after
          border-bottom-color: white
          inset-inline: 0


      .ant-menu-item-active,
      .ant-menu-submenu-active
        &:hover
          &:after
            border-bottom-color: white
            inset-inline: 0
      .ant-menu-submenu
        .ant-menu-submenu-title
          display: flex
          flex-direction: row-reverse

          .ant-menu-title-content
            margin-left: 0
            margin-right: 5px
      .ant-menu-submenu-selected
        .ant-menu-item-icon
          color: white



  &__logo
    display: flex
    align-items: center

    .airportName
      margin-left: 1.2rem

    .ant-menu-overflow-item
      padding: 0
      margin-right: 20px

  &__wrapperRightSideBar
    display: flex
    align-items: center

    .wrapperAlertLink
      width: 80px

      .link
        display: flex
        align-items: center
        justify-content: space-evenly
        .ant-badge
          display: flex
          flex-direction: row-reverse
          align-items: center
          .badge_alert_text
            font-size: 1rem
            font-weight: 600
            margin-right: 5px
        &:hover
          .ant-badge
            .badge_alert_text
              color: $white
    .wrapperSwitchTime
      display: flex
      flex-direction: column
      margin: 0 10px


#block_container
  width: 100%
  text-align: left
  display: flex
  align-items: center
  justify-content: space-between


#first_block
  width: 197px
  height: 58px
  margin-left: 0.5em

  img
    width: 100%

.link
  color: $white

.wrapperPopoverAvatarContent
  h3
    text-align: center
    font-weight: 500
    text-transform: uppercase

  &__popoverAvatarContent
    padding: 20px 10px
    display: flex
    flex-direction: column

    button
      @extend .defaultButton
      margin-bottom: 8px
      line-height: 1.1rem

      &:last-child
        margin: 0

.ant-menu-vertical
  li
    font-weight: 600
    padding: 0 !important
    text-align: center
