.modalWrapper
  @include defModalClasses
  background-color: rgba(0, 0, 0, 1)

.modalWrapper.openImgModal
  @include defOpenModalClasses
  padding: 3px 0

  .big_img_wrapper
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    .positioned_draw_btn_section
      position: absolute
      width: auto
      bottom: 0
      left: 50%
      transform: translateX(-50%)

    &__img_section
      width: 100%
      height: calc(100% - 60px)
      position: relative
      padding: 10px
      .fallback_loading_wrapper, .fallback_error_wrapper
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
      .zoomable_img_wrapper
        //width: 100%
        height: 100%
        position: relative
        border-radius: 10px
        animation-name: zoom
        animation-duration: 0.6s
        -webkit-animation-name: zoom
        -webkit-animation-duration: 0.6s
        .ant-carousel,
        .slick-slider,
        .slick-list,
        .slick-track
          height: 100% !important

        .slick-slide > div:first-child
          height: 100% !important

        .react-transform-wrapper, .transform-component-module_wrapper__7HFJe,
        .react-transform-component, .transform-component-module_content__uCDPE
          border-radius: inherit
          width: 100%
          height: 100%
          justify-content: center

          img
            width: inherit
            height: inherit
            z-index: 1
          canvas
            width: inherit
            height: inherit

            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0
            z-index: 2

    &__draw_btn_section
      width: 100%
      height: 40px
      display: flex
      justify-content: center
      align-items: center

      .input_file_container
        width: 30px
        height: 30px
        background-color: #ad8b00
        border: 1px solid $white
        border-radius: 10px
        margin-right: 10px
        &:hover
          transform: scale(1.1)
          transition: all 0.4s linear
          border: none
          .input_file_trigger
            color: $black
        .input_file_trigger
          position: relative
          color: $white
          cursor: pointer
          .file_input
            position: absolute
            top: 0
            left: 0
            z-index: -1
            opacity: 0
        .input_file_trigger, .file_input
          width: 100%
          height: 100%
          display: inline-block
          text-align: center
          line-height: 1.85rem
          border-radius: inherit
      .disabled_input_file_container
        cursor: not-allowed
        border-color: #d9d9d9
        color: rgba(0, 0, 0, 0.25)
        background-color: rgba(204, 204, 204, 0.5)
        &:hover
          transform: scale(1)
          transition: none
          background-color: rgba(204, 204, 204, 0.5)
        .input_file_trigger, .file_input
          pointer-events: none
        .input_file_trigger
          color: rgba(0, 0, 0, 0.25)

      .ant-btn
        width: 160px
        height: 30px
        line-height: 15px
        padding: 4px 8px
        &:hover
          transform: scale(1.1)
          transition: all 0.4s linear
          border-color: $white !important // fix important
        &:disabled
          background-color: rgba(204, 204, 204, 0.5)
          &:hover
            border: 1px solid #ffffff !important // fix important
            transform: scale(1)

      .ant-btn.final_btn
        background-color: $green
      .ant-btn.cancelled_btn
        background-color: $red

      .ant-btn,
      .ant-tooltip-disabled-compatible-wrapper,
      .ant-popover-disabled-compatible-wrapper,
      .ant-color-picker-trigger
        margin-right: 10px
        &:last-child
          margin-right: 0


      .informButton
        width: 30px
        height: 30px
        padding: 0
        background-color: #d46b08

  .landmarks_wrapper
    width: 100%
    display: flex
    justify-content: space-around
    &__left-side,
    &__right-side
      width: 49%

    &__left-side
      .landmark-drawing-wrapper
        width: 100%
        &__drawing-resize-wrapper
          width: 100%
          position: relative
          border-radius: 10px
          z-index: 1
        &__drawing-resize-wrapper.disabled-modal-block
          &:hover
            cursor: not-allowed
      .landmark-drawing-error-wrapper
        display: flex
        justify-content: center
        align-items: center

      .react-transform-wrapper, .transform-component-module_wrapper__7HFJe,
      .react-transform-component, .transform-component-module_content__uCDPE
        border-radius: inherit
        width: 100%
        justify-content: center

        img, canvas
          width: 100%
          height: 100%

        canvas
          position: absolute
          top: 0
          left: 0
          right: 0
          bottom: 0

      .ant-landmarks-left-side-table-wrapper
        .ant-table-cell-pixel
          width: 25%
        .ant-table-cell-coordinate
          width: 55%
        .ant-table-cell-actions
          width: 15%
        .ant-table-cell-pixel,
        .ant-table-cell-coordinate,
        .ant-table-cell-actions
          .ant-btn
            width: 35px
            height: 35px
            padding: 0
            &:disabled
              background-color: #d9d9d9
              &:hover
                border: none !important // fix it
          .break-remove-btn-item,
          .remove-landmark-btn-row
            background-color: #f5222d
          .ant-popover-disabled-compatible-wrapper
            background-color: transparent
          .start-remove-btn-item, .save-btn-item
            background-color: #389e0d
          .break-remove-btn-item
            margin-right: 4px

    &__right-side
      position: relative
      border-radius: 5px 5px 0 0
      z-index: 1
      .google_map_wrap
        width: 100%
        height: calc(100vh - 38px)
        position: relative
        .google_maps
          width: 100%
          height: 100%
          border-bottom-left-radius: 5px

      .autocomplete-wrapper, .autocomplete-input-container
        border-radius: inherit
      .autocomplete-wrapper
        position: relative
        .autocomplete-input-container
          display: flex
          border: 1px solid #000
          background-color: #ffffff
          .ant-input, .ant-btn
            border: none
            background-color: transparent
          .ant-btn
            color: #000000
            padding: 0
            &:hover
              border: none !important /// fix it
              transform: scale(1.1)
              transition: all 0.4s
          .ant-input
            &:focus
              box-shadow: none




        .autocomplete-suggestions-list
          width: 100%
          position: absolute
          top: 33px
          border: 1px solid #000
          border-radius: 0 0 5px 5px
          z-index: 2
          background-color: #ffffff
          .autocomplete-suggestion-list-item
            list-style-type: none
            padding-left: 5px
            cursor: pointer
            font-weight: 500

            &:hover
              background-color: #1f618d
              color: #fff
              &:last-child
                border-radius: 0 0 4px 4px


.modalWrapper.closeImgModal
  @include defCloseModalClasses

.buttonPrevMoveBigImg, .buttonNextMoveBigImg
  background-color: #3C60B5
  font-size: 2rem
  height: 100%

@keyframes zoom
  from
    transform: scale(0)

  to
    transform: scale(1)