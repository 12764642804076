@import "global/screenSizes"

.container
  width: 100%
  max-width: calc(100% - 40px)
  margin: 15px auto
.turnarounds-container
  &__custom-table
    .turnarounds-cameras
      text-align: center
      &__camera-wrapper
        .camera
          display: flex
          justify-content: center
          align-items: center
          &__camera-icon
            color: $lightOrange
            margin-right: 3px
          &__camera-info
            color: $black
            font-weight: 500
          &:hover
            transform: scale(1.1)
            transition: all 0.5s ease-in-out
    .turnarounds-id-btn-container
      &__btn
        font-size: 0.8rem
        background-color: transparent
        border: none
        cursor: pointer
        text-decoration: underline
        color: $darkBlue

        &:hover
          transform: scale(1.1)
          transition: all 0.5s ease-in-out
    .turnarounds-events
      position: relative
      &__validated-status
        position: absolute
        top: 50%
        right: 0px
        transform: translateY(-50%)
        .ant-tag
          margin: 0
          padding-inline: 4px




// media query
@media (max-width: $layout-breakpoint-small)
  .turnarounds-container
    &__custom-table
      .ant-table-container
        .ant-table-header
          .ant-table-thead
            tr
              .ant-table-cell
                padding: 5px
                .antd-column-title
                  text-transform: capitalize
                  font-size: 0.7rem
        .ant-table-body
          tr
            .ant-table-cell
              font-size: 0.70rem
