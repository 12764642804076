@import "GlobalStyles"

.wrapperLoginForm
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 300px
  border: 1px solid
  padding: 20px
  background-color: #fff
  border-radius: 10px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  &__title
    text-align: center
    text-transform: uppercase
    margin-bottom: 5px
    h1
      margin: 0


.mainForm
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  .blockInput
    width: 100%
    border: 1px solid
    padding: 10px 15px
    border-radius: 6px

  &__wrapperInput
    width: 100%
    text-align: center
    margin-bottom: 10px
    input
      width: 100%
      outline: none
      border: none

    .inputError
      font-weight: 600
      color: red
      line-height: 20px

    .passwordBlockInput
      width: 100%
      display: flex
      input
        width: 100%
        border: none
        padding: 0 5px
        outline: none

      button
        border: none
        background-color: transparent
        border-left: 1px solid
        padding-left: 10px
        cursor: pointer

  &__regForgetWrapper
    width: 100%
    display: flex
    font-size: 0.9rem
    justify-content: center
    margin-bottom: 10px
  &__regButton
    margin-bottom: 0
    button
      cursor: pointer
      width: 100%
      background-color: transparent
      border: none
      text-transform: uppercase
      color: #0B139D
      font-weight: 600
      &:hover
        text-decoration: underline

  &__submitButton
    @extend .defaultButton
    width: 150px !important
    height: 40px
    color: #fff




