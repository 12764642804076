@import "GlobalStyles"

.gates-cameras-container
  width: 100%
  max-width: 80%
  margin: 0 auto
  &__camera-modal-info-wrapper
    @include defModalClasses
    background-color: rgba(31, 97, 141, 0.8)

  &__camera-modal-info-wrapper.open
    @include defOpenModalClasses

  &__camera-modal-info-wrapper.close
    @include defCloseModalClasses

  .gates-cameras-table
    .ant-table
      .ant-table-container
        .ant-table-header
          .ant-table-cell
            &:first-child
              border-start-start-radius: 5px
            &:last-child
              border-start-end-radius: 5px
    .gates-cameras-title
      .ant-btn
        width: 150px
        background-color: $blue
        color: $white
        &:hover
          border-color: $black
          transition: all 0.4s ease-in-out
    .ant-table-bordered
      .ant-table-title
        padding: 0 0 5px 0
        border: none
    .ant-table-body
      tbody
        .ant-table-cell
          .ant-btn
            @include defBntIcon
          .editable_disabled_btn_container
            .ant-btn
              &:first-child
                color: rgba(0, 0, 0, 0.25)
                cursor: not-allowed
          .configuration-camera
            padding: 0 15px
            border-inline-start: 1px solid #c0c0c0
            display: inline-block
            &:first-child
              border-inline-start: none
              padding-left: 0
            &:last-child
              padding-right: 0
            &__camera-title
              .camera-icon-result-container
                display: flex
                align-items: center
                justify-content: center
                &__camera-icon
                  margin: 0
                  .ant-btn-icon
                    width: 20px
                    height: 20px
                    svg
                      width: inherit
                      height: inherit
                &__camera-icon.connected
                  .ant-btn-icon
                    svg
                      fill: $green
                &__camera-icon.disconnected
                  .ant-btn-icon
                    svg
                      fill: $red
                &__camera-icon.disabled
                  .ant-btn-icon
                    svg
                      fill: $grey

                &__calibration-result
                  width: 20px
                  height: 20px
                  font-size: 1.2rem

                &__calibration-result > span
                  display: flex
                &__calibration-result.animated
                  svg
                    animation: spin 2s infinite linear
                    transform-origin: center
                &__calibration-result.failed
                  display: flex
                  justify-content: center
                  align-items: center
                  position: relative
                  svg
                    display: block

                  .failed-icon-result
                    display: flex
                    justify-content: center
                    align-items: center
                    width: 100%
                    height: 100%
                    position: absolute
                    top: 0
                    left: 0
                    opacity: 0.7
                    svg
                      display: block
                      fill: $black

                &__calibration-result.bridge
                  display: none

            &__btn-camera-container
              .ant-btn
                margin-right: 5px
                &:last-child
                  margin-right: 0
              .ant-switch
                margin-top: 5px
              .ant-switch.unaltered
                background-color: $red
              .ant-switch.modified
                background-color: $blue

  .gate-camera-info-container
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)
    z-index: 100
    width: 95%
    height: 100%
    padding: 5px 0 10px 0

    &__camera-title
      position: relative
      height: 35px
      display: flex
      justify-content: center
      align-items: center
      background-color: $white
      border-radius: 5px

      p
        font-weight: 700
        span
          font-weight: 400
      .ant-btn
        position: absolute
        right: 5px
        top: 50%
        transform: translateY(-50%)
        background-color: transparent
        padding: 3px
        width: auto
        height: auto
        color: $red
        border-color: $red

    &__zoomable-carousel-frame-container
      width: 100%
      height: calc(100% - 85px)
      position: relative
      border-radius: 10px
      padding: 5px 0
      display: flex
      justify-content: center
      align-items: center

      .ant-carousel
        width: 100%
        .slick-next, .slick-prev
          width: auto
          height: auto
          opacity: 0.6
          color: $black
          font-weight: 600
          &:hover
            opacity: 1
            color: $white

        .slick-next, .slick-prev
          font-size: 1.1rem
          &:after
            top: -5.5px
            inset-inline-start: auto
        .slick-next
          &:after
            right: -12px
        .slick-prev
          &:after
            left: -12px
        .slick-disabled
          opacity: 0
          pointer-events: none


      .ant-carousel,
      .slick-slider,
      .slick-list,
      .slick-track
        height: 100%

        .slick-slide > div:first-child
          height: 100%
          display: flex
          justify-content: center

        .react-transform-wrapper, .transform-component-module_wrapper__7HFJe,
        .react-transform-component, .transform-component-module_content__uCDPE
          border-radius: inherit
          height: 100%
          justify-content: center

          img
            width: 100%
            height: 100%
            object-fit: contain

    &__camera-description
      height: 50px
      display: flex
      justify-content: center
      align-items: center
      background-color: $white
      border-radius: 5px

      p
        text-transform: capitalize
        font-weight: 700
        margin-right: 10px
        span
          font-weight: 400


