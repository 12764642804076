@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600&display=swap')
@import "styles/variables"

@import "styles/Header"
@import "styles/Detections"
@import "styles/FormAddGate/NewGate"
@import "styles/FormAddGate/FormAndCameras"
@import "styles/FormAddGate/Camera"
@import "styles/FormAddGate/ImageSettig"
//@import "styles/FormAddGate/BigImg"
@import "styles/Gate"
@import "styles/SignIn"
@import "styles/GatesCameras"
@import "styles/Errors"
@import "styles/Turnarounds"
@import "styles/Canvas"
@import "styles/Turnaround"
@import "styles/Live"
@import "styles/SkeletonLoadings"
@import "styles/Users"
@import "styles/Image"
@import "styles/LiveView"
@import "styles/Mock"
@import "styles/System"
@import "styles/Performance"
@import "styles/Spinner"
@import "styles/AuditLogs"

@import "styles/FormAddGate/BigImgNewStyles"

@import "styles/FormAddGate/BigImgNewStyles"

@import "styles/Turnarounds"

//.containerNewTable
//  width: 100%
//  max-width: calc(100% - 40px)
//  margin: 15px auto

*
  margin: 0
  padding: 0
  box-sizing: border-box
  font-family: 'Titillium Web', sans-serif
  text-decoration: none


html, body
  height: 100%
  margin: 0
  overflow-x: hidden

.ant-table
  .ant-table-container
    border-top: none
    border-inline-start: 1px solid #c0c0c0
    .ant-table-header
      .ant-table-thead
        tr
          .ant-table-column-sort
            background-color: #1f618d
          .ant-table-column-has-sorters
            &:hover
              background-color: #1f618d
          .ant-table-cell
            text-align: center
            text-transform: capitalize
            &:first-child
              border-start-start-radius: 5px
            &:last-child
              border-start-end-radius: 5px
    .ant-table-body
      .ant-table-tbody
        .ant-table-row
          .ant-table-column-sort
            background: #f2f2f2
            border-bottom: 1px solid #ffffff
          .ant-table-cell
            text-align: center
            font-weight: 600
            border-inline-end: 1px solid #c0c0c0
            border-bottom: 1px solid #c0c0c0

.ant-table-filter-trigger,
.ant-table-column-sorter,
.ant-table-column-sorter-full
  .anticon
    margin: 0
    &:hover
      color: $white
      transition: all 0.4s ease-in-out

  .anticon, .ant-table-column-sorter-inner
    color: black
    .ant-table-column-sorter-down.active, .ant-table-column-sorter-up.active
      color: $lightOrange
.ant-table-filter-trigger.active
    svg
      fill: $lightOrange


// GATES and CAMERAS
.gates-cameras-container
  .gates-cameras-table
    .ant-table
      .ant-table-container
        border-inline-start: 1px solid #72706E
        border-top: none
        border-top-left-radius: 5px
        .ant-table-body
          .ant-table-tbody
            .ant-table-row
              .ant-table-cell
                border-inline-end: 1px solid #72706E
                border-bottom: 1px solid #72706E

// expandable Table styles
.performanceWrapper, .live-view-wrapper
  tbody
    .ant-table-row-level-0
      .ant-table-cell
        font-weight: 600
    .ant-table-expanded-row-level-1
      .ant-table-cell
        padding: 7px
        border-inline-end: none
        text-align: center
  .nestedTable
    .ant-table-thead
      text-transform: capitalize
      .ant-table-cell
        background-color: #f0f0f0
        border-start-start-radius: 0
        border-start-end-radius: 0
        color: black
        padding: 10px
        text-align: center

        &:before
          background-color: rgba(5, 5, 5, 0.06)
        &:first-child
          border-start-start-radius: 0
        &:last-child
          border-start-end-radius: 0

    tbody
      .disabled-row
        background-color: #EBEBE4
        pointer-events: none
        color: #C6C6C6
      .ant-table-row-hover
        &:hover
          background-color: #CECECE
          opacity: 1

.turnaround-details-container
  text-align: center

  &__details
    .details-section
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    font-weight: 600
    p
      span
        margin-left: 5px
        font-weight: 450

.markItem
  background-color: $yellow
  color: #000000
  padding: 2px
  border-radius: 5px
  margin-right: 0
  font-size: 0.7rem