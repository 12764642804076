// page NOT_FOUND


.wrapper_notFound
  text-align: center
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

  .notFoundText
    font-weight: 600
    margin: 0


  .buttonHome
    background: transparent
    font-size: 3rem
    border: none
    color: #FF0000

    &:hover
      transform: scale(1.2)
      cursor: pointer
      transition: all 0.4s linear


.request_error
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

  &__information
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column

    font-size: 2.5rem
    font-weight: 600
    color: $red

  &__buttons
    display: flex
    justify-content: space-evenly
    align-items: center
    margin-top: 30px
    font-size: 3rem

    button
      background-color: transparent
      border: none
      cursor: pointer
      color: $darkBlue

      &:hover
        transform: scale(1.3)
        transition: all 0.4s linear
        color: $red