/// step two form add gate skeleton styles

.formGate

  &__wrapperSkeletonInput
    width: 60%
    margin-bottom: 10px
    padding: 5px


  &__skeletonLabel
    width: 45%
    margin: 0 auto

  &__skeletonRadioButtons
    display: flex
    align-items: center
    justify-content: center

  &__skeletonSubmit
    width: 87px