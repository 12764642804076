@import "../GlobalStyles"

.imageSetting
  position: static
  h1
    font-weight: 500

.wrapperImage
  width: 400px
  height: 230px
  position: relative
  display: flex
  justify-content: center
  border-radius: inherit
  .previewImgLoadingWrapper,
  .previewImgErrorWrapper,
  .preview_simple_img_loading_wrapper,
  .preview_simple_img_error_wrapper
    width: 100%
    height: 100%
    display: flex
    justify-content: center
  .previewImgLoadingWrapper, .preview_simple_img_loading_wrapper
    align-items: center
  .previewImgErrorWrapper, .preview_simple_img_error_wrapper
    img
      width: 100%
  //.previewImgErrorWrapper
  //  img
  //    width: 100%
  //    height: 100%

  .ant-carousel, img
    width: 100%

  .ant-carousel,
  .slick-slider,
  .slick-list,
  .slick-track
    height: 100% !important

    .slick-slide > div:first-child
      height: 100% !important
      img
        height: 100%

  .input_file_container, .input_file_container:before
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    border-radius: inherit
  .input_file_container
    width: 100%
    height: 100%
    &:before
      content: ""
      background-color: rgb(0, 0, 0)
      opacity: 0
      z-index: 1
    &:hover:before
      opacity: 0.5
      transition: 0.3s ease-in-out
    .file_input, .input_file_trigger
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      opacity: 0
      cursor: pointer
    .file_input
      z-index: 2

    .input_file_trigger
      font-size: 2.5rem
      z-index: 3
      color: white
      &:hover
        opacity: 1
        transition: 0.3s ease-in-out


.wrapperSettingButtons
  width: 100%
  margin: 10px 0
  display: flex
  justify-content: space-around
  align-items: center

  button
    @extend .defaultButton
    width: 150px !important
    text-align: center
    padding: 0 !important
    &:disabled
      &:hover
        background-color: rgba(0, 0, 0, 0.04)
        border-color: #d9d9d9 !important
        color: rgba(0, 0, 0, 0.25) !important

.wrapperFinishButtons
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  background-color: transparent
  button
    width: 100px
    line-height: 1.1rem
    &:hover
      color: #000000
      border: 1px solid #000

.settingImageLoading
  position: static
  top: 0
  left: 0
  transform: translate(0 ,0)



.wrapperErrorImage
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  &__header
    margin-bottom: 20px
    text-align: center
    font-size: 1.1rem
    font-weight: 600
    h1
      color: #FF0000
