@import "GlobalStyles"

.live-view-wrapper
  width: 80%
  margin: 10px auto 0 auto
  tbody
    .ant-table-row-level-0
      &:hover
        .ant-table-cell
          background-color: #d9d9d9 !important
      .ant-table-cell
        padding: 14px

  &__column
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center

      .ant-tag, .ant-badge
        margin: 0
      .ant-tag
        color: $black
      .ant-badge
        .ant-badge-status-dot
          width: 13px
          height: 13px
      .turnTimeLineMark
        span
          &:first-child
            margin-right: 5px
          &:last-child
            margin-left: 5px

  .turnaround_column
    .ant-tag
      cursor: pointer
      &:hover
        transform: scale(1.1)
        transition: all 0.4s linear

  .stream_liveView_container
    width: 60px
    height: 60px
    margin: 0 auto
    border-radius: 10px
    border: 1px solid #000000
    &:hover
      transform: scale(1.1)
      transition: all 0.4s linear
    .ant-image
      border-radius: inherit
    a
      display: inline-block
      width: 100%
      height: 100%
      .ant-image-img
        object-fit: cover