@import "GlobalStyles"

//PreviewUserComponent
.wrapperAdministration
  width: 95%
  margin: 10px auto 0 auto

  .ant-table-wrapper
    .ant-spin-container
      .ant-table
        .ant-table-title
          display: flex
          justify-content: center

  .ant-table-thead
    .ant-table-cell
      &:last-child
        width: 200px !important

  &__blockAddNewButton
    display: flex
    justify-content: center
    button
      @extend .defaultButton
      width: 150px !important
      display: flex
      justify-content: center
      line-height: 1.2rem

  .ant-table-thead
    .ant-table-cell
      text-align: center
.sectionPreviewUser
  @extend .wrapperModal

.userCard
  width: 500px
  .ant-card-head-title
    text-align: center
    font-size: 1.2rem
    font-weight: 550
  .closeButtonCard
    position: absolute
    top: 10px
    right: 5px
    border: none
    background-color: transparent
    font-size: 1.3rem
    cursor: pointer
    &:hover
      color: $red
      transform: scale(1.1)
      transition: all 0.4s linear
  .userCardHeader
    display: flex
    justify-content: space-between
    align-items: center

  .userCardBody
    display: flex
    justify-content: space-between
    align-items: center

    .sectionUserDetails
      .userDetail
        display: flex
        margin-bottom: 5px

        span
          &:first-child
            width: 50px
.wrapperUserForm
  width: 80%
  margin: 0 auto

  .bodyEditForm, .bodyNewForm
    display: flex
    justify-content: space-around

    &__userFormDetails, &__userFormPermissions
      width: 450px
      .ant-card-head
        text-align: center
        font-size: 1.1rem
        font-weight: 550
        text-transform: capitalize

      .ant-card-body
        label
          justify-content: center
          width: 80px
  .bodyNewForm
    margin-top: 10px
  .ant-form
    .user_header_form
      margin: 10px 0
      text-align: center
  .submitUserFooter
    display: flex
    justify-content: center
    background-color: transparent
    margin-top: 10px
    .ant-form-item
      margin-bottom: 0 !important
    button
      @extend .defaultButton
      width: 140px
      height: 35px
      line-height: 1.2rem
      margin-bottom: 0
      text-transform: uppercase
      &:first-child
        margin-right: 10px



//EditUserComponent


//userActions
.userActions
  display: flex
  justify-content: center
  &__actionButton
    @extend .iconButton
    margin-right: 10px

.usersStatus, .usersRole
  text-transform: uppercase
  color: $black !important
  margin-right: 0 !important
  font-size: 0.8rem

.userCard.active
  @extend .showCard

.userCard.notActive
  @extend .hideCard

.sectionPreviewUser.active
  @extend .showModal

.sectionPreviewUser.notActive
  @extend .hideModal

