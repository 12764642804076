$black: #000000
$white: #ffffff
$middleWhite: #D2D2D2
$yellow: #FFE100
$blue: #1F618D
$darkBlue: #0B139D
$middleBlue: #3C60B5
$easy-blue: #1f618d
$smoke: #D2D2D2
$middleSmoke: #D5D5D5
$lightOrange: #FF8500
$darkOrange: #A65600
$birch: #25ad96
$middleBirch: #06799F
$red: #FF0000
$green: #00C322
$grey: gray


$main_header_height: 70px
$header_turn_camera_height: 53px
$collapse_header_event_table_height: 28px
$footer_event_table_height: 28px

$padding_top_turn_content: 5px
$header_turn_margin_bottom: 5px
$collapse_margin_bottom: 10px

$sum_several_collapses_margin_bottom: $collapse_margin_bottom * 2
$sum_margins_and_paddings_for: $padding_top_turn_content + $header_turn_margin_bottom + $sum_several_collapses_margin_bottom

$sum_all_collapses_header_event_table_height: $collapse_header_event_table_height * 2
$sum_all_footers_event_table_height: $footer_event_table_height * 2

$margin_for_hide_main_scroll: 10px

$sum_busy_space_for_table: $main_header_height + $header_turn_camera_height + $sum_all_collapses_header_event_table_height + $footer_event_table_height + $sum_margins_and_paddings_for
$sum_busy_space_for_all_tables: $main_header_height + $header_turn_camera_height + $sum_all_collapses_header_event_table_height + $sum_all_footers_event_table_height + $sum_margins_and_paddings_for + $margin_for_hide_main_scroll
