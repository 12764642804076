@import "variables"

.systemWrapper,
.exportWrapper
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  flex-direction: column
  margin-top: 10px
  width: 100%

  &__downloadWrapper, &__presentationWrapper
    width: inherit
    .downloadSection, .presentationSection
      display: flex
      justify-content: center
      align-items: center
      width: inherit
      &__systemCardBlock, &__presentationCardBlock
        height: 350px
        width: 400px
        max-width: 100%
        margin-right: 20px
        &:last-child
          margin-right: 0
        .ant-card-head
          min-height: 30px
        .ant-card-body
          .ant-form, .ant-form-horizontal
            margin: 0
            section
              .ant-form-item
                margin-bottom: 10px
      &__presentationCardBlock
        .cameraSettingsSection
          display: flex
          justify-content: center
          align-items: center
          margin-bottom: 20px
          span
            display: inline-block
            margin-right: 10px
        .cameraSettingsFooter
          button
            width: 150px
            color: $black
            text-transform: uppercase
            font-weight: 600


.turnCardDataBlock,
.validCardDataBlock,
.imagesCardDataBlock
  .ant-card-body
    footer
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center

      .ant-btn
        width: 200px
        margin-bottom: 10px
        &:last-child
          margin-bottom: 0

.validCardDataBlock
  &__rangeDateSection
    width: 350px
    margin: 0 auto 10px auto


.event_mode_container,
.monitoring_object_container
  width: 90%
  margin: 0 auto
  &__header
    display: flex
    justify-content: center
    margin-bottom: 10px
    .header_select_container
      &:last-child
        margin-left: 10px
      .ant-select
        width: 300px
        margin-right: 10px
        &:last-child
         margin-right: 0
  .event_mode_table,
  .monitoring_object_table
    .ant-table-header
      .ant-table-cell
        align-items: center
        .ant-table-filter-trigger,
        .ant-table-column-sorter-full
          margin-inline: 4px -3px
          margin-block: -2px
          background: rgba(15, 55, 82, 0.5)
      .event_mode_btn_edit_container,
      .monitoring_btn_edit_container
        &__dropdown_title_block
          position: relative
          .ant-btn
            width: 25px
            height: 25px
            position: absolute
            right: 0
            top: 50%
            transform: translateY(-50%) rotate(90deg)
            background-color: transparent
            border-radius: 0
            border: none
            color: $white
            font-weight: 600
            padding: 0
            .ant-btn-icon
              .anticon
                color: $black
                background: rgba(15, 55, 82, 0.5)
                border-radius: 5px
                font-size: 1.5rem
              &:hover
                .anticon
                  color: $white
                  transition: all 0.4s ease-in-out

    .ant-table-body
      .event_mode_btn_edit_container > div,
      .monitoring_btn_edit_container > div
        display: flex
        justify-content: center
        align-items: center

      .event_mode_btn_edit_container,
      .monitoring_btn_edit_container
        .ant-tag
          margin-right: 0
        .ant-form-item
          display: inline-block
          max-width: 160px
          width: 100%
          .ant-form-item-row
            width: inherit
        .ant-btn
          height: 22px
          margin-left: 10px
          font-size: 1rem
          padding: 0
          background-color: transparent
          border: none
          color: $black
          box-shadow: none
          &:hover
            color: $lightOrange
            transform: scale(1.2)
            transition: all 0.4s linear
          &:disabled
            color: $smoke
            &:hover
              color: $smoke
              transition: none
              transform: scale(1)
      .required_event_checkbox
        .ant-checkbox-inner
          border-color: $black

