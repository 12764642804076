.imageSpinner
  width: 50px
  height: 50px
  display: flex
  justify-content: center
  align-items: center
  margin: 0 auto

.ant-image-preview-operations-operation-rotateRight,
.ant-image-preview-operations-operation-rotateLeft
  display: none