.accountWrapper
  width: 60%
  margin: 10px auto 0 auto

  &__userDetails,
  &__changeUserPassword
    width: 550px
    margin: 0 auto

  &__changeUserPassword
    margin-top: 15px

  .details,
  .changePassword
    border: 3px solid #1f618d
    .ant-card-head-title
      font-size: 1.2rem

  //Details
  .details
    position: relative

    &__editButton
      position: absolute
      top: 5px
      right: 5px
      background-color: transparent
      border: none
      font-size: 1.2rem
      cursor: pointer

      &:hover
        color: #FF0000
        transform: scale(1.1)

    .detailsInput
      width: 200px

    .detailsFormItem,
    .detailsText
      margin-bottom: 7px

    .detailsText

      span:last-child
        font-weight: 600
        display: inline-block
        height: 32px
        padding: 4px 11px


  //Change Password
  &__changeUserPassword

    .changePassword
      text-align: center

      &__form
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

        .changePassInput
          width: 300px

