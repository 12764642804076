@keyframes spinner
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)

.loading-spinner
  width: 40px
  height: 40px
  border: 10px solid #f3f3f3
  border-top: 10px solid #1890ff
  border-radius: 50%
  animation: spinner 1.5s linear infinite


.big_loading
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

