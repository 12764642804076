// Costum Button ANTD
@import "variables"

.defaultButton
  text-align: center
  width: 100px
  border-radius: 10px !important
  background-color: $blue
  font-size: 1.1rem
  color: $white

  &:hover
    color: $black !important
    border: 2px solid $black !important
    background-color: #1677ff

.iconButton
  cursor: pointer
  background-color: transparent
  border: none
  box-shadow: none
  color: $easy-blue !important
  font-size: 1.2rem
  padding: 0

  &:hover
    background-color: transparent !important
    transform: scale(1.1)
    transition: all 0.4s linear
    color: $lightOrange !important

  &:disabled
    color: $grey !important
    background-color: transparent
    border: none

@mixin iconButtonTest
  cursor: pointer
  background-color: transparent
  border: none
  box-shadow: none
  color: $easy-blue !important
  font-size: 1.2rem
  padding: 0

  &:hover
    background-color: transparent !important
    transform: scale(1.1)
    transition: all 0.4s linear
    color: $lightOrange !important

  &:disabled
    color: $grey !important
    background-color: transparent
    border: none


// Costum Button ANTD


//MODAL STYLES

.wrapperModal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  overflow: auto
  padding-top: 70px
  z-index: 99
  text-align: center
  cursor: pointer


.showModal
  display: flex
  justify-content: center
  align-items: center

.hideModal
  display: none


.showCard
  position: absolute
  z-index: 100
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.hideCard
  display: none

.animation
  animation-name: zoom
  animation-duration: 0.6s
  -webkit-animation-name: zoom
  -webkit-animation-duration: 0.6s


//MODAL STYLES




//MIXINS

@mixin defModalClasses
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: rgba(0, 0, 0, 0.5)
  z-index: 99


@mixin defOpenModalClasses
  display: flex

@mixin defCloseModalClasses
  display: none


@mixin defBtn

@mixin defBntIcon
  cursor: pointer
  background-color: transparent
  border: none
  box-shadow: none
  color: $easy-blue
  padding: 0

  &:hover
    background: transparent
    transform: scale(1.1)
    transition: all 0.4s linear
    color: $lightOrange

  &:disabled
    color: $grey
    background-color: transparent
    border: none

  .anticon
    font-size: 1.1rem





@keyframes scale
  from
    transform: scale(0)

  to
    transform: scale(1)

@-webkit-keyframes scale
  from
    transform: scale(0)

  to
    transform: scale(1)

@keyframes spin
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)