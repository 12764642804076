.mock_wrapper
  width: 90%
  margin: 20px auto 0 auto

  &__mock_form_section
    margin-bottom: 20px
    display: flex
    justify-content: center
    align-items: center
    .mock_select
      margin-right: 20px
      width: 300px
      &:last-child
        width: 300px
    .time_picker_mock
      margin-right: 20px
    .submit_mock_btn
      cursor: pointer


  &__flight_mock_information
    display: flex
    flex-direction: column
    align-items: center

    .flight_information
      width: 600px
      .ant-card-body
        display: flex
        justify-content: space-between
      &__flight_file_card_description
        margin-bottom: 10px
        text-align: center
        div
          flex: 1
        h2, .ant-card-head
          text-decoration: underline

      .arrival_description,
      .departure_description,
      .general_description > p > span
        font-weight: 600

  &__flightInfoCameras
    display: flex
    width: 100%
    overflow-x: auto
    .flightInfoCamerasScrollContainer
      width: 1800px
      display: flex
      justify-content: center
    .ant-card-head
      text-decoration: underline
    .flightCamera
      text-align: center
      margin-right: 10px
      &:last-child
        margin-right: 0
    .ant-card
      padding: 12px
      width: 250px !important

    .cameraDescription > p > span
      font-weight: 600


