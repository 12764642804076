.mainWrapperDetections
  margin: 5px 0
  display: flex
  justify-content: center
  align-items: center

.wrapperDetectionZone
  margin-right: 10px
  font-size: 0.9rem
  display: flex
  align-items: center

  span
    font-weight: 550
    display: inline-block
    margin-left: 5px

