.formName
    width: 300px
    padding: 20px
    border-radius: 10px
    border: 5px solid #3C60B5
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    header
        margin-bottom: 10px
        h1
            font-size: 1.3rem
.ant-select-dropdown
    .configured_gate_name_option
        .ant-select-item-option-content
            text-decoration: line-through
            font-weight: 600
            color: $red
            text-decoration-color: $black
