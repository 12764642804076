@import "../GlobalStyles"


.wrapperEditCamera, .wrapperAddCamera
    display: flex
    justify-content: center

.wrapperEditCamera
    //width: 700px
    margin: 15px auto 0 auto

.wrapperFormAndPreview
    display: flex
    justify-content: center
    align-items: center
    margin: 15px auto 0 auto


.wrapperCameras
    width: 100%
    display: flex
    border-radius: 10px
    padding: 30px
    flex-direction: column
    text-align: center
    background-color: #06799F
    position: relative
    z-index: 0

.wrapperCameras h2
    margin-bottom: 20px
    border-bottom: 1px solid
    font-weight: 550

.cameras
    display: flex
    flex-wrap: wrap

    &__saveButton
        width: 150px
        margin: 0 auto
        height: 30px
        font-size: 1.1rem
        font-weight: 600
        text-transform: uppercase
        background-color: #3C60B5
        border-radius: 5px
        color: #fff
        cursor: pointer
        &:hover
            opacity: 0.8

.formGate
    //position: sticky
    //top: 0
    //width: 500px
    padding: 10px
    border-radius: 10px
    border: 5px solid #3C60B5
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin: 0 30px 30px 30px

    &__wrapperInput
        width: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        //margin-bottom: 10px
        label
            font-size: 1.2rem
            font-weight: 600
            margin-bottom: 5px
        input[type=text],
        input[type=url],
        input[type=text],
        input[type=url]
            width: 60%
            padding: 5px
        select
            width: 60%
            text-align: center
            font-size: 1rem
            font-weight: 600
            padding: 3px
        input[type=text],
        input[type=url],
        select
            border: 2px solid
            border-radius: 5px
            background-color: #fff
    button
        @extend .defaultButton
.active
    margin-right: 10px


.active > input, .notActive > input
    margin-right: 10px


.errorInput
    color: #FF0000
    text-align: center
    display: inline-block
    margin: 5px 0


.emptyBlock
    width: 600px
    text-transform: uppercase
    font-size: 1.2rem
    color: #fff
    h1, h2
        font-weight: 550

.imageSetting
    height: 400px