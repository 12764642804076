.audit_range-picker-container
  .ant-picker-dropdown
    z-index: 9999
    .ant-picker-panels > *:first-child button.ant-picker-header-next-btn
      visibility: visible !important

    .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn
      visibility: visible !important

    .ant-picker-range-arrow
      display: none !important

    .ant-picker
      width: 457px

    .ant-picker-panel-container,
    .ant-picker-footer
      width: 457px !important


    .ant-picker-panel-container
      box-shadow: none !important


    .ant-picker-footer-extra > div
      flex-wrap: wrap !important


    .ant-picker-body
      padding: 0 8px !important

  .table-date-filter-spacer
    height: 336px


  .date-filter-dropdown
    width: 457px
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
    border: none
    border-bottom: 1px solid #d9d9d9


  .ant-picker-footer
    position: relative
    height: 40px !important
    .ant-picker-footer-extra
      padding: 0 !important
      height: inherit
      position: absolute
      top: 50%
      transform: translateY(-50%)
      left: 12px
      display: flex
      align-items: center
      .ant-table-filter-dropdown-btns
        padding: 0 !important
        border: none
        .ant-btn
          border: none
          box-shadow: none
          background-color: transparent
        .ant-btn, .ant-btn:hover
          color: #FF0000
          &:disabled, &:disabled:hover
            border-color: #d9d9d9
            color: rgba(0, 0, 0, 0.25)
            background: rgba(0, 0, 0, 0.04)

    .ant-picker-ok
      .ant-btn
        background-color: #1f618d
        color: #ffffff
        &:hover
          color: #000000
          border-color: #1f618d
          background-color: transparent
        &:disabled
          border: none
          color: rgba(0, 0, 0, 0.25)
          background: rgba(0, 0, 0, 0.04)
        span
          &:after
            font-size: 1rem


  .ant-picker-dropdown
    padding: 0 !important
    width: 457px !important

  .ant-picker-panel-container
    box-shadow: none !important
    border-radius: 0 !important