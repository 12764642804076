.canvas_div
    position: relative
    overflow: hidden
    background-color: #000000
    border-radius: 10px
    height: 100%
    border: 1px solid #000000

    &__downloadImage
        position: absolute
        right: 5px
        bottom: 5px
        z-index: 4
        width: 30px
        height: 30px
        border: 1px solid #000000
        border-radius: 10px

        &:hover
            transform: scale(1.1)
            transition: all 0.4s
        button
            cursor: pointer
            background-color: transparent !important
            border: none !important
            font-size: 1.1rem !important
            border-radius: 0
            width: 100%
            height: 100%
            color: #ffffff !important
            padding: 0 !important

.keypoints_layer
    z-index: 1
    bottom: 10px

.detections_layer
    z-index: 2

.clearance_zone_layer
    z-index: 3

.trackers_layer
    z-index: 5

.projections_layer
    z-index: 6

.event_bbox_layer
    z-index: 7

.vests_layer
    z-index: 8

.other_projections_layer
    z-index: 9

.keypoints_layer,
.detections_layer,
.clearance_zone_layer,
.trackers_layer,
.projections_layer,
.event_bbox_layer,
.vests_layer,
.other_projections_layer
    position: absolute
    left: 0
    top: 0
    background: transparent

.keypoints_layer,
.detections_layer,
.clearance_zone_layer,
.trackers_layer,
.projections_layer,
.event_bbox_layer,
.vests_layer,
.image_layer,
.other_projections_layer
    width: 100%
    border-radius: 10px


//.react-transform-wrapper,
//.react-transform-component
//    width: 100% !important
//    height: 100% !important



