@import "variables"

.performanceWrapper
  width: 95%
  max-width: 1920px
  margin: 20px auto

  .ant-spin-nested-loading
    .ant-spin-container
      .ant-pagination
        margin: 10px 0 0 0

  &__performanceForm
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 15px
    .ant-form-item
      margin: 0 10px 0 0
      &:last-child
        margin: 0
